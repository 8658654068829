"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDevAuth = void 0;
var client_1 = require("@apollo/client");
exports.getDevAuth = (0, client_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getDevAuth($username: String!, $password: String!) {\n    devAuth(username: $username, password: $password) {\n      authorized\n      accessToken\n      csrfToken\n      metadata {\n        userId\n        participantId\n        username\n        firstName\n        lastName\n        emailAddress\n        objectTypeId\n        userTypeId\n        reportingLevel\n        hasSisterHotels\n        userStatus\n        invalidLoginCount\n        disabled\n        customerFlags\n        creditCardInfoViewable\n        testUser\n        apiUser\n        adminUser\n        participantType\n        accountMappingId\n        UserStub\n        isCSO\n      }\n    }\n  }\n"], ["\n  query getDevAuth($username: String!, $password: String!) {\n    devAuth(username: $username, password: $password) {\n      authorized\n      accessToken\n      csrfToken\n      metadata {\n        userId\n        participantId\n        username\n        firstName\n        lastName\n        emailAddress\n        objectTypeId\n        userTypeId\n        reportingLevel\n        hasSisterHotels\n        userStatus\n        invalidLoginCount\n        disabled\n        customerFlags\n        creditCardInfoViewable\n        testUser\n        apiUser\n        adminUser\n        participantType\n        accountMappingId\n        UserStub\n        isCSO\n      }\n    }\n  }\n"])));
var templateObject_1;
