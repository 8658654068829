import React from 'react';
import { Col } from '@cvent/carina/components/Col';
import { Row } from '@cvent/carina/components/Row';
import Button from '@cvent/carina/components/Button';
import { Textbox } from '@cvent/carina/components/Forms/BasicFields/Textbox';
import { Form } from '@cvent/carina/components/Forms/Form';
import { css } from '@emotion/react';

interface PasskeyLoginProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (event: any, onSubmit: any) => Promise<void>;
}

const loginForm = css({
  width: '200px',
  margin: '2rem auto'
});

export function PasskeyDevLogin({ onSubmit }: PasskeyLoginProps): JSX.Element {
  const nonEmpty = (value: string) => (!value || value.trim() === '' ? 'The field is required!' : undefined);

  return (
    <div css={loginForm}>
      <Row justifyContent="center">
        <Form
          element="form"
          onSubmit={(event, formValues) => {
            if (!formValues.hasErrors) {
              onSubmit(event, formValues);
            }
          }}
        >
          <Textbox name="username" label="User Name" validate={nonEmpty} />
          <Textbox name="password" type="password" label="Password" validate={nonEmpty} />
          <Row justifyContent="center">
            <Col width="content">
              <Button appearance="filled" variant="interactive" text="Login" type="submit" />
            </Col>
          </Row>
        </Form>
      </Row>
    </div>
  );
}
