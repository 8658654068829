import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import { LoggerFactory } from '@cvent/logging/LoggerFactory';
import { PasskeyDevLogin } from '@components/PasskeyDevLogin';
import { useLazyQuery } from '@apollo/client';
import cookie from 'js-cookie';
import { getDevAuth } from '@cvent/passkey-admin-model/operations/authentication';
import { DEFAULT_AUTH_COOKIE_NAME } from '@cvent/nextjs/auth/constants';
import { setItem } from '@cvent/nextjs/utils/storage';
import getConfig from 'next/config';
import { MetaData, MetadataFields } from '@components/types';

const { publicRuntimeConfig } = getConfig();

export const LOG = LoggerFactory.create('LoginHandler');
export const RESDESK_COOKIE = `RD4_ID_${publicRuntimeConfig.COOKIE_HOST}`;

function LoginPage(): JSX.Element {
  const router = useRouter();
  const { query } = router;

  const [loginQuery] = useLazyQuery(getDevAuth);

  const login = useCallback(
    async (event, formValues) => {
      // Authenticate user and generate access token for local development workflow

      const response = await loginQuery({
        variables: formValues.values
      });
      const responseValues = response.data.devAuth;
      LOG.debug(`DEV Login Response: ${JSON.stringify(responseValues)}`);
      const metadata: MetaData = responseValues?.metadata;

      if (response.data.devAuth.authorized) {
        cookie.set(DEFAULT_AUTH_COOKIE_NAME, responseValues?.accessToken);
        setItem('csrfToken', responseValues?.csrfToken);
        setItem(MetadataFields.USER_ID, metadata?.userId?.toString());
        setItem(MetadataFields.USER_NAME, metadata?.username);
        setItem(MetadataFields.LAST_NAME, metadata?.lastName);
        setItem(MetadataFields.FIRST_NAME, metadata?.firstName);
        setItem(MetadataFields.CUSTOMER_FLAGS, metadata?.customerFlags?.toString());
        setItem(MetadataFields.USER_TYPE_ID, metadata?.userTypeId?.toString());
        setItem(MetadataFields.OBJECT_TYPE_ID, metadata?.objectTypeId?.toString());
        setItem(MetadataFields.PARTICIPANT_ID, metadata?.participantId?.toString());
        setItem(MetadataFields.PARTICIPANT_TYPE, metadata?.participantType);
        setItem(MetadataFields.HAS_SIS_HOTELS, metadata?.hasSisterHotels?.toString());
        setItem(MetadataFields.USER_EMAIL, metadata?.emailAddress);
        setItem(MetadataFields.ADMIN_USER, metadata?.adminUser?.toString());
        // is corporate standards organization
        setItem(MetadataFields.IS_CSO, metadata?.isCSO?.toString());
        router.push((query.returnUrl as string) || '/');
      }
    },
    [query.returnUrl, router, loginQuery]
  );

  return <PasskeyDevLogin onSubmit={login} />;
}

export default LoginPage;
